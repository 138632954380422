<template>
<div>
<template>
<div>
<div>
    <DatePicker
        v-model="getValue"
        @input="handleDatePicker"
        class="w-full dateInputhide"
        :class="{ 'invalid': error }"
        format="DD MMM, YYYY"
        value-type="YYYY-MM-DD"
        :clearable="false"
        :key="getFieldId + getValue"
        :placeholder="getPlaceholderVal"
        :disabled="disabled || formAlreadySubmitted"
    />
<div class="dateInputshow">
     <input @focus="handleDate" ref="myInput" :class="{ 'invalid': error }" v-model="getDate"  autocomplete="off" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline dateInput" id="username" type="text" :placeholder="getPlaceholderVal">
     <svg @click="handleDate" xmlns="http://www.w3.org/2000/svg" class="svgIcon" viewBox="0 0 1024 1024" width="1em" height="1em"><path d="M940.218182 107.054545h-209.454546V46.545455h-65.163636v60.50909H363.054545V46.545455H297.890909v60.50909H83.781818c-18.618182 0-32.581818 13.963636-32.581818 32.581819v805.236363c0 18.618182 13.963636 32.581818 32.581818 32.581818h861.090909c18.618182 0 32.581818-13.963636 32.581818-32.581818V139.636364c-4.654545-18.618182-18.618182-32.581818-37.236363-32.581819zM297.890909 172.218182V232.727273h65.163636V172.218182h307.2V232.727273h65.163637V172.218182h176.872727v204.8H116.363636V172.218182h181.527273zM116.363636 912.290909V442.181818h795.927273v470.109091H116.363636z"></path></svg>
</div>
</div>
</div>
</template>
<div>
<div class="datePickerView">
   <Drawer ref="drawer" >
        <div>
            <div class="m-6">
            <template>
              <div v-if="opened">
                <DateModal v-if="opened" :closeDrawer="closeDrawer" @gdate="gdate"/>
              </div>
            </template>
            </div>
        </div>
    </Drawer>
</div>
</div>
</div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import DateModal from "./dateModal.vue"
import { DateTime } from "luxon";
// import { generateRandomString } from "@/utils/functions.js"
import debounce from "lodash/debounce";

const Drawer = () => import("./dateTime");
export default {
    components: {
        DatePicker,DateModal,Drawer
    },
    props: {
        fieldData: {
            type: Object,
            require: true,
        },
        error: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        formAlreadySubmitted: {
            type: Boolean,
        }
    },
    data() {
        return {
            key: '',
            getDate:'',
            opened: false,
            // inputMode: 'none'
        }
    },
    computed: {
        getValue: {
            get() {
                return this.fieldData.value || " ";
            },
            set(newValue) {
            //    let data = { value: newValue, field_id: this.fieldData.field_id };
            //     if (this.fieldData.multi_id) {
            //         data.multi_id = this.fieldData.multi_id;
            //         delete data.field_id
            //     }
            //     EventBus.$emit("changeFieldValue", data);
                this.$emit('input', newValue);
            },
        },
        getPlaceholderVal() {
            return this.fieldData?.placeholder || "";
        },
        getFieldId() {
            return this.fieldData.field_id || "";
        },
    },

    methods: {
        // generateRandomString,
        gdate(val,type,dateVal){
            if(!val) {
                let dateValue = DateTime.fromJSDate(new Date(this.fieldData.value)).toFormat('dd LLL, yyyy')
                this.getDate = this.fieldData.value ? dateValue : ''
            }
            this.$forceUpdate();
            // this.fieldData.value = JSON.parse(JSON.stringify(dateVal));
            if(dateVal) {
                this.fieldData.value = dateVal ? JSON.parse(JSON.stringify(dateVal)) : null;
                this.getDate= val ? JSON.parse(JSON.stringify(val)) : null;
            }
            this.$emit("handleFieldValueChnage", this.fieldData.value);
        },
        
        debouncedHandleDatePicker: debounce(function (val) {
          this.getValue = val;
        }, 300),
        
        handleDatePicker(val) {
          this.debouncedHandleDatePicker(val);
        },
        handleDate(){
            this.openDrawer()
            this.$refs.myInput.blur();
            // if(this.inputMode == 'none'){
              this.opened = true;
            // }
        }, 
        openDrawer() {
            this.$refs.drawer.open();
        },
        closeDrawer() {
            this.$refs.drawer.close();
            this.opened = false;
        },
    },
    mounted() {
        // this.key = this.generateRandomString()
        this.gdate()
    },
};
</script>
<style src="vue2-datepicker/index.css"></style>
<style lang="scss" scoped>
.w-full.mx-datepicker {
    width: 100% !important;
}
::v-deep {
    .mx-input {
        height: 40px !important;
        box-shadow: none !important;
        -webkit-box-shadow: none !important;
        border-radius: 4px !important;
        @apply border-box-stroke focus:border-blue-500;
        &:focus {
            box-shadow: 0 0 0 1px rgba(37, 99, 235, var(--tw-border-opacity)) !important;
        }
        &:hover {
            box-shadow: 0 0 0 1px rgba(37, 99, 235, var(--tw-border-opacity)) !important;
        }
    }
}
.invalid {
    ::v-deep {
        input {
            @apply border-red-600;
        }
    }
}
.datePickerView{
    display:none !important;
}
.dateInputshow{
    display:none;
}
@media (max-width: 500px){
.datePickerView{
    display:block !important;
}
.mx-datepicker-content{
    display:none !important;
}
.dateInputshow{
    display:block;
}
.dateInputhide{
    display:none;
}
.dateInput {
    position: relative;
}
.svgIcon {
    position: absolute;
    right: 9px;
    top: 14px;
    font-size: 1em;
    color: rgba(0,0,0,.5) !important;
}
} 
@media screen and (min-width: 500px) and (max-width: 1024px){
.datePickerView{
    display:block !important;
}
.mx-datepicker-content{
    display:none !important;
}
.dateInputshow{
    display:block !important;
}
.dateInputhide{
    display:none !important;
}
.dateInput {
    position: relative;
}
.dateInputEnd {
    position: relative;
}
.svgIconEnd {
   position: absolute;
    right: 9px;
    top: 14px;
    font-size: 1em;
    color: rgba(0,0,0,.5) !important;
}
.svgIcon {
    position: absolute;
    right: 9px;
    top: 14px;
    font-size: 1em;
    color: rgba(0,0,0,.5) !important;
}
}
</style>
